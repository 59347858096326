import React from 'react';
import { graphql } from 'gatsby';
import { BlogPost, Layout, SEO } from '../components';

export const BlogPostTemplate = ({ data }) => {
  const { contentfulBlogPost: article } = data;
  return (
    <Layout noBackground>
      <SEO title={article.title} description={article.description?.description} />
      <BlogPost article={article}  />
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      author {
        name
      }
      description {
          description
      }
      slug
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 960) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
    }
  }
`;
